import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <iframe className="center" width="560" height="315" src="https://www.youtube.com/embed/aysyFVflljU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <p>{`When I built the website for `}<a parentName="p" {...{
        "href": "https://landingintech.com"
      }}>{`Landing in Tech`}</a>{` I wanted to have a bar on top of the page with the latest episode. `}</p>
    <p>{`The idea was to allow users to listen to the episode while browsing the page.`}</p>
    <p>{`I added the iframe on my `}<inlineCode parentName="p">{`layout.js`}</inlineCode>{` file and expected it to work, obviously, it didn't. With each route change, the whole layout reloads which means that you would lose your progress if you started to listen to the episode.`}</p>
    <p>{`Yesterday, I asked on Twitter if anyone knew a way to fix this issue. `}<a parentName="p" {...{
        "href": "https://twitter.com/BasileSamel"
      }}>{`Baz`}</a>{` gave great advice on how to achieve this. He mentioned that Gatsby should have a way to handle this, that was my lightbulb moment - perhaps using the `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/docs/browser-apis/"
      }}><inlineCode parentName="a">{`gatsby-browser`}</inlineCode></a>{` api, might do exactly what I needed.`}</p>
    <h2 {...{
      "id": "a-lucky-strike",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-lucky-strike",
        "aria-label": "a lucky strike permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A lucky strike`}</h2>
    <p>{`I have to admit that I came to the solution by luck. I was scrolling through the browser api pages and came across the `}<inlineCode parentName="p">{`wrapRootElement`}</inlineCode>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"This is useful to set up any Provider components that will wrap your application. For setting persistent UI elements around pages use wrapPageElement."
`}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.com/docs/browser-apis/"
        }}>{`Gatsby documentation`}</a></p>
    </blockquote>
    <p>{`After playing around with it, I have realised that both `}<inlineCode parentName="p">{`wrapRootElement`}</inlineCode>{` and `}<inlineCode parentName="p">{`wrapPageElement`}</inlineCode>{` do what I wanted, but since Gatsby suggests using the former for persistent UI elements, I've used that one instead.`}</p>
    <p>{`Let's open the `}<inlineCode parentName="p">{`gatsby-browser.js`}</inlineCode>{` file and add the SoundCloud iframe inside our Layout.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const React = require("react")
const Layout = require("./src/components/layout").default

exports.wrapPageElement = ({ element }) => {
    return (
        <Layout>
            <div className="sound-cloud">
                <iframe
                    className="mb-0"
                    width="100%"
                    height="20"
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    title="latest episode"
                    src="https://w.soundcloud.com/player/..."
                />
            </div>
            {element}
        </Layout>
    )
}
`}</code></pre>
    <h2 {...{
      "id": "refactoring-pages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#refactoring-pages",
        "aria-label": "refactoring pages permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Refactoring Pages`}</h2>
    <p>{`Since I am importing the Layout and using it in `}<inlineCode parentName="p">{`wrapPageElement`}</inlineCode>{` that means that each page will have two `}<inlineCode parentName="p">{`Layout`}</inlineCode>{` components, the one that I have imported in each page and the one being set in `}<inlineCode parentName="p">{`gatsby-browser`}</inlineCode>{`. `}</p>
    <p>{`This meant that I had Ito refactor every page and remove the Layout component on every single one. A tedious task, but the end result does exactly what I wanted.`}</p>
    <p><strong parentName="p">{`Update`}</strong></p>
    <p>{`After sharing this article on Twitter a lot of great developers told me that I need to add the same to the `}<inlineCode parentName="p">{`gatsby-ssr.js`}</inlineCode>{` file, this is because Gatsby uses that file when running gatsby build. So, make sure you add the code to both `}<inlineCode parentName="p">{`gatsby-browser`}</inlineCode>{` and `}<inlineCode parentName="p">{`gatsby-ssr`}</inlineCode>{` file.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      